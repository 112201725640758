
export const NOTE_PREFIX = 'note'
export const SMS_PREFIX = 'sms'
export const MEETING_PREFIX = 'meeting'
export const RODO_PREFIX = 'rodo'
export const CONTACT_PREFIX = 'contact'

export function resolveContactStatusVariant(status) {

    if (status == '109') return 'info' // in_progress
    if (status == '110') return 'success' // meeting
    if (status == '111') return 'danger' // rejected
    return 'primary' // new
}
export function resolveMeetingStatusVariant(status) {

    if (status == '114') return 'info' // videokonferennce
    if (status == '115') return 'success' // live
    // if (status == '111') return 'danger' // rejected
    return 'primary' // new
}


