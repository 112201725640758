//rerendering: true; to solve problem with rerender route using the same component
// resource: 'Auth' for all logged in users

export default [
    {
        path: '/dashboard',
        // replace:true,
        name: 'dashboard',
        component: () => import('@/views/pages/dashboard/dashboard.vue'),
        meta: {
            resource: 'Auth',
            // action: 'view',
            title: 'label_dashboard'
        },
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: () => import('@/views/pages/contact/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'contact.tab_details',
            action: 'view',
            rerendering: true,
            title: 'label_potential_clients'
        },
    },
    {
        path: '/contacts-new',
        // replace:true,
        name: 'contacts-new',
        component: () => import('@/views/pages/contact/entry.vue'),
        meta: {
            // component_rerendering:true,
            // layout: 'full',
            resource: 'contact.tab_details',
            action: 'view',
            rerendering: true,
            title: 'label_new_clients'
        },
    },
    {
        path: '/contacts-in-progress',
        name: 'contacts-in-progress',
        component: () => import('@/views/pages/contact/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'contact.tab_details',
            action: 'view',
            rerendering: true,
            title: 'label_clients_in_progress'
        },
    },

    {
        path: '/contact/tab-details/:id',
        name: 'contact-details',
        component: () => import('@/views/pages/contact/tabs.vue'),
        meta: {
            // layout: 'full',
            resource: 'contact.tab_details',
            action: 'view',
            title: 'label_potential_clients',
        },
    },
    {
        path: '/contact/tab/:alias/:id',
        name: 'contact_tabs',
        component: () => import('@/views/pages/contact/tabs.vue'),
        meta: {
            rerendering:true,
            // layout: 'full',
            resource: 'contact.tab_details',
            action: 'view',
            title: 'label_potential_clients',
        },
    },

    //AGREEMENTS
    {
        path: '/agreements',
        name: 'agreements',
        component: () => import('@/views/pages/agreement/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'agreement.tab_details',
            action: 'view',
            title: 'label_agreements',
        },
    },
    {
        path: '/agreement/tab-details/:id',
        name: 'agreement-details',
        component: () => import('@/views/pages/agreement/tabs.vue'),
        meta: {
            // layout: 'full',
            resource: 'agreement.tab_details',
            action: 'view',
            title: 'label_agreements',
        },
    },
    {
        path: '/agreement/add/:contact_id?',
        name: 'agreement_add',
        component: () => import('@/views/pages/agreement/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'agreement.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_add_agreement',
        },
    },
    {
        path: '/agreement/edit/:id',
        name: 'agreement_edit',
        component: () => import('@/views/pages/agreement/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'agreement.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_agreement',
        },
    },
    {
        path: '/agreement/task/:id',
        name: 'agreement_task_details',
        component: () => import('@/views/pages/agreement/taskDetails.vue'),
        meta: {
            // layout: 'full',
            resource: 'agreement.tab_tasks',
            title: 'label_task',
            action: 'view',
        },
    },
    {
        path: '/agreement/tab/:alias/:id',
        name: 'agreement_tabs',
        component: () => import('@/views/pages/agreement/tabs.vue'),
        meta: {
            rerendering:true,
            // layout: 'full',
            resource: 'agreement.tab_details',
            action: 'view',
            title: 'label_agreements',
        },
    },
    {
        path: '/deal/task/:id',
        name: 'deal_task_details',
        component: () => import('@/views/pages/agreement/taskDetails.vue'),
        meta: {
            // layout: 'full',
            resource: 'deal.tab_tasks',
            action: 'view',
            title: 'label_tasks',
        },
    },

    // DEALS
    {
        path: '/deals',
        name: 'deals',
        component: () => import('@/views/pages/deal/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'deal.tab_details',
            action: 'view',
            title: 'label_deals',
        },
    },
    {
        path: '/deal/tab-details/:id',
        name: 'deal-details',
        component: () => import('@/views/pages/deal/tabs.vue'),
        meta: {
            // layout: 'full',
            resource: 'deal.tab_details',
            action: 'view',
            title: 'label_deals',
        },
    },
    {
        path: '/deal/tab/:alias/:id',
        name: 'deal_tabs',
        component: () => import('@/views/pages/deal/tabs.vue'),
        meta: {
            rerendering:true,
            // layout: 'full',
            resource: 'deal.tab_details',
            action: 'view',
            title: 'label_deals',
        },
    },
    {
        path: '/deal/add',
        name: 'deal_add',
        component: () => import('@/views/pages/agreement/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'deal.tab_details',
            action: 'edit',
            title: 'label_add_deal',
        },
    },
    {
        path: '/deal/edit/:deal_id/:id',
        name: 'deal_edit',
        component: () => import('@/views/pages/agreement/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'deal.tab_details',
            action: 'edit',
            title: 'label_edit',
        },
    },
    {
        path: '/mails/undecided',
        name: 'mails_undecided',
        component: () => import('@/views/pages/mail/undecided.vue'),
        meta: {
            // layout: 'full',
            resource: 'mail.tab_details',
            action: 'view',
            title: 'label_undecided',

        },
    },
    {
        path: '/mails/incoming',
        name: 'mails_incoming',
        component: () => import('@/views/pages/mail/incoming.vue'),
        meta: {
            // layout: 'full',
            resource: 'mail.tab_details',
            action: 'view',
            title: 'label_incoming',
        },
    },
    {
        path: '/mails/outcoming',
        name: 'mails_outcoming',
        component: () => import('@/views/pages/mail/outcoming.vue'),
        meta: {
            // layout: 'full',
            resource: 'mail.tab_details',
            action: 'view',
            title: 'label_outcoming',

        },
    },
    {
        path: '/mails/edit/:id',
        name: 'mail_edit',
        component: () => import('@/views/pages/mail/processItemForm.vue'),
        meta: {
            // layout: 'full',
            resource: 'mail.tab_details',
            action: 'edit',
            title: 'label_mails',
        },
    },
    {
        path: '/mails/add',
        name: 'mail_add',
        component: () => import('@/views/pages/mail/processItemForm.vue'),
        meta: {
            // layout: 'full',
            resource: 'mail.tab_details',
            action: 'edit',
            title: 'label_mail',

        },
    },
    {
        path: '/mail-members',
        name: 'mail_members',
        component: () => import('@/views/pages/mail/mail_members.vue'),
        meta: {
            // layout: 'full',
            resource: 'mail.tab_details',
            action: 'view',
            title: 'label_senders_and_recipients_short',
        },
    },

    {
        path: '/tasks',
        name: 'tasks',
        component: () => import('@/views/pages/task/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'agreement.tab_tasks',
            action: 'view',
            title: 'label_tasks',
        },
    },
    {
        path: '/working-time',
        name: 'working_time',
        component: () => import('@/views/pages/workingtime/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'deal.tab_working_time_report',
            action: 'view',
            title: 'label_working_time',
        },
    },
    {
        path: '/payments',
        name: 'payments',
        component: () => import('@/views/pages/payment/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'agreement.tab_payments',
            action: 'view',
            title: 'label_payments',
        },
    },
    {
        path: '/receivables',
        name: 'receivables',
        component: () => import('@/views/pages/receivables/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'agreement.tab_payments',
            action: 'view',
            title: 'label_receivables',
        },
    },
    {
        path: '/procurations',
        name: 'procurations',
        component: () => import('@/views/pages/procurations/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'deal.tab_procurations',
            action: 'view',
            title: 'label_procurations',
        },
    },


    // contractregistry
    {
        path: '/contract-registry',
        name: 'contract_registry',
        component: () => import('@/views/pages/contract-registry/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'contractregistry.tab_details',
            action: 'view',
            title: 'label_agreement_register',
        },
    },
    {
        path: '/contract-registry-details/:id',
        name: 'contract_registry_details',
        component: () => import('@/views/pages/contract-registry/itemDetails.vue'),
        meta: {
            // layout: 'full',
            resource: 'contractregistry.tab_details',
            action: 'view',
            title: 'label_agreement_register',
        },
    },
    {
        path: '/contract-categories',
        name: 'contract_categories',
        component: () => import('@/views/pages/contract-registry/categories.vue'),
        meta: {
            // layout: 'full',
            resource: 'contractregistry.tab_details',
            action: 'view',
            title: 'label_agreement_register',
        },
    },

    // contactregistry
    {
        path: '/contact-registry',
        name: 'contact_registry',
        component: () => import('@/views/pages/contact-registry/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'contactregistry.tab_details',
            action: 'view',
            title: 'label_contact_register',
        },
    },
    {
        path: '/contact-registry-details/:id',
        name: 'contact_registry_details',
        component: () => import('@/views/pages/contact-registry/itemDetails.vue'),
        meta: {
            // layout: 'full',
            resource: 'contactregistry.tab_details',
            action: 'view',
            title: 'label_contact_register',
        },
    },
    {
        path: '/contact-categories',
        name: 'contact_categories',
        component: () => import('@/views/pages/contact-registry/categories.vue'),
        meta: {
            // layout: 'full',
            resource: 'contactregistry.tab_details',
            action: 'view',
            title: 'label_contact_register',
        },
    },
    // REPORTS
    {
        path: '/reports',
        name: 'reports',
        component: () => import('@/views/pages/report/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'report.tab_details',
            action: 'view',
            title: 'label_reports',
        },
    },
    // CALENDAR
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('@/views/pages/calendar/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'calendar.tab_details',
            action: 'view',
            title: 'label_calendar',
        },
    },
    // CHAT
    {
        path: '/chat',
        name: 'chat',
        component: () => import('@/views/pages/chat/entry.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'chat-application',
            resource: 'chat.tab_details',
            action: 'view',
            title: 'label_chat',
        },
    },
    // USERS
    //ADMINS
    {
        path: '/admins',
        name: 'admins',
        component: () => import('@/views/pages/admin/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'subadmin.tab_details',
            action: 'view',
            title: 'label_admins',
        },
    },
    {
        path: '/admin/tab-details/:id',
        name: 'admin-details',
        component: () => import('@/views/pages/admin/tabs.vue'),
        meta: {
            resource: 'admin.tab_details',
            action: 'view',
            title: 'label_admins',
        },
    },
    {
        path: '/admins/edit/:id',
        name: 'admins_edit',
        component: () => import('@/views/pages/admin/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'subadmin.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_admins',
        },
    },
    {
        path: '/admins/add',
        name: 'admins_add',
        component: () => import('@/views/pages/admin/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'subadmin.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_admins',
        },
    },
    //SOLICITORS
    {
        path: '/solicitors',
        name: 'solicitors',
        component: () => import('@/views/pages/solicitor/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'solicitor.tab_details',
            action: 'view',
            title: 'label_solicitors',
        },
    },
    {
        path: '/solicitor/tab/:alias/:id',
        name: 'solicitor_tabs',
        component: () => import('@/views/pages/solicitor/tabs.vue'),
        meta: {
            component_rerendering:true,
            // layout: 'full',
            resource: 'solicitor.tab_details',
            action: 'view',
            title: 'label_solicitors',
        },
    },
    {
        path: '/solicitor/details/:id',
        name: 'solicitor-details',
        component: () => import('@/views/pages/solicitor/tabs.vue'),
        meta: {
            // layout: 'full',
            resource: 'solicitor.tab_details',
            action: 'view',
            title: 'label_solicitors',
        },
    },
    {
        path: '/solicitors/edit/:id',
        name: 'solicitors_edit',
        component: () => import('@/views/pages/solicitor/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'solicitor.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_solicitors',
        },
    },
    {
        path: '/solicitors/add',
        name: 'solicitors_add',
        component: () => import('@/views/pages/solicitor/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'solicitor.tab_details',
            action: 'view',
            rerendering: true,
            title: 'label_solicitors',
        },
    },

    //CONSULTANTS
    {
        path: '/consultants',
        name: 'consultants',
        component: () => import('@/views/pages/consultant/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'consultant.tab_details',
            action: 'view',
            title: 'label_consultants',
        },
    },
    {
        path: '/consultant/tab-details/:id',
        name: 'consultant-details',
        component: () => import('@/views/pages/consultant/tabs.vue'),
        meta: {
            resource: 'consultant.tab_details',
            action: 'view',
            title: 'label_consultants',
        },
    },
    {
        path: '/consultants/edit/:id',
        name: 'consultant_edit',
        component: () => import('@/views/pages/consultant/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'consultant.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_consultants',
        },
    },
    {
        path: '/consultants/add',
        name: 'consultant_add',
        component: () => import('@/views/pages/consultant/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'consultant.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_consultants',
        },
    },
    //ACCOUNTANTS
    {
        path: '/accountants',
        name: 'accountants',
        component: () => import('@/views/pages/accountant/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'accountant.tab_details',
            action: 'view',
            title: 'label_accountants',
        },
    },
    {
        path: '/accountant/tab-details/:id',
        name: 'accountant-details',
        component: () => import('@/views/pages/accountant/tabs.vue'),
        meta: {
            resource: 'accountant.tab_details',
            action: 'view',
            title: 'label_accountants',
        },
    },
    {
        path: '/accountants/edit/:id',
        name: 'accountant_edit',
        component: () => import('@/views/pages/accountant/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'accountant.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_accountants',
        },
    },
    {
        path: '/accountants/add',
        name: 'accountant_add',
        component: () => import('@/views/pages/accountant/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'accountant.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_accountants',
        },
    },


    // AGENTS
    {
        path: '/agents',
        name: 'agents',
        component: () => import('@/views/pages/agent/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'agent.tab_details',
            action: 'view',
            title: 'label_table_agents',
        },
    },
    {
        path: '/agent_payments',
        name: 'agent_payments',
        component: () => import('@/views/pages/agent/payments.vue'),
        meta: {
            // layout: 'full',
            resource: 'agent.payment',
            action: 'view',
            title: 'payments',
        },
    },
    {
        path: '/agent/tab/:alias/:id',
        name: 'agent_tabs',
        component: () => import('@/views/pages/agent/tabs.vue'),
        meta: {
            component_rerendering:true,
            // layout: 'full',
            resource: 'agent.tab_details',
            action: 'view',
            title: 'label_table_agents',
        },
    },
    {
        path: '/agent/details/:id',
        name: 'agent-details',
        component: () => import('@/views/pages/agent/tabs.vue'),
        meta: {
            // layout: 'full',
            resource: 'agent.tab_details',
            action: 'view',
            title: 'label_table_agents',
        },
    },
    {
        path: '/agents/edit/:id',
        name: 'agents_edit',
        component: () => import('@/views/pages/agent/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'agent.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_table_agents',
        },
    },
    {
        path: '/agents/add',
        name: 'agents_add',
        component: () => import('@/views/pages/agent/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'agent.tab_details',
            action: 'view',
            rerendering: true,
            title: 'label_table_agents',
        },
    },
    {
        path: '/agent-invoice/:id/:invoice_id',
        name: 'agent_invoice',
        component: () => import('@/views/pages/agent/agentInvoice.vue'),
        meta: {
            // layout: 'full',
            resource: 'agreement.tab_payments',
            // resource: 'report.agent_invoice',
            action: 'view',
            title: 'label_table_agents',
        },
    },


    // {
    //     path: '/agent/details/:id',
    //     name: 'agent-details',
    //     component: () => import('@/views/pages/agreement/tabs.vue'),
    //     meta: {
    //         // layout: 'full',
    //         resource: 'agreement',
    //         action: 'edit',
    //     },
    // },


    // {
    //     path: '/solicitor/details/:id',
    //     name: 'solicitor-details',
    //     component: () => import('@/views/pages/agreement/tabs.vue'),
    //     meta: {
    //         // layout: 'full',
    //         resource: 'agreement',
    //         action: 'edit',
    //     },
    // },

// OTHER
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            // resource: 'Auth',
            action: 'read',
            title: 'Error 404',
        },
    },
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/pages/authentication/Login.vue'),
        meta: {
            layout: 'full',
            // resource: 'Auth',
            redirectIfLoggedIn: true,
            title: 'label_login',
        },
    },
    // {
    //     path: '/pages/authentication/login-v1',
    //     name: 'auth-login-v1',
    //     component: () => import('@/views/pages/authentication/Login-v1.vue'),
    //     meta: {
    //         layout: 'full',
    //     },
    // },
    // {
    //     path: '/pages/authentication/login-v2',
    //     name: 'auth-login-v2',
    //     component: () => import('@/views/pages/authentication/Login-v2.vue'),
    //     meta: {
    //         layout: 'full',
    //     },
    // },
    {
        path: '/register',
        name: 'auth-register',
        component: () => import('@/views/pages/authentication/Register.vue'),
        meta: {
            layout: 'full',
            // resource: 'Auth',
            redirectIfLoggedIn: true,
            title: 'label_register',
        },
    },
    // {
    //     path: '/pages/authentication/register-v1',
    //     name: 'auth-register-v1',
    //     component: () => import('@/views/pages/authentication/Register-v1.vue'),
    //     meta: {
    //         layout: 'full',
    //     },
    // },
    // {
    //     path: '/pages/authentication/register-v2',
    //     name: 'auth-register-v2',
    //     component: () => import('@/views/pages/authentication/Register-v2.vue'),
    //     meta: {
    //         layout: 'full',
    //     },
    // },
    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
        meta: {
            layout: 'full',

            redirectIfLoggedIn: true,
            title: 'label_password',
        },
    },
    // {
    //     path: '/pages/authentication/forgot-password-v1',
    //     name: 'auth-forgot-password-v1',
    //     component: () => import('@/views/pages/authentication/ForgotPassword-v1.vue'),
    //     meta: {
    //         layout: 'full',
    //     },
    // },
    // {
    //     path: '/pages/authentication/forgot-password-v2',
    //     name: 'auth-forgot-password-v2',
    //     component: () => import('@/views/pages/authentication/ForgotPassword-v2.vue'),
    //     meta: {
    //         layout: 'full',
    //         resource: 'Auth',
    //     },
    // },
    {
        path: '/auth/reset-password/:secret',
        name: 'auth-reset-password',
        component: () => import('@/views/pages/authentication/ResetPassword.vue'),
        meta: {
            layout: 'full',
            // resource: 'Auth',
            // redirectIfLoggedIn: true,
            title: 'label_password',
        },
    },
    // {
    //     path: '/pages/authentication/reset-password-v2',
    //     name: 'auth-reset-password-v2',
    //     component: () => import('@/views/pages/authentication/ResetPassword-v1.vue'),
    //     meta: {
    //         layout: 'full',
    //     },
    // },
    {
        path: '/coming-soon',
        name: 'misc-coming-soon',
        component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/auth/not-authorized',
        name: 'misc-not-authorized',
        component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    },
    {
        path: '/under-maintenance',
        name: 'misc-under-maintenance',
        component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/error',
        name: 'misc-error',
        component: () => import('@/views/pages/miscellaneous/Error.vue'),
        meta: {
            layout: 'full',
        },
    },

    // SECRETARIES
    {
        path: '/secretaries',
        name: 'secretaries_list',
        component: () => import('@/views/pages/secretaries/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'secretary.tab_details',
            action: 'view',
            title: 'label_secretaries',
        },
    },
    {
        path: '/secretary/tab-details/:id',
        name: 'secretary-details',
        component: () => import('@/views/pages/secretaries/tabs.vue'),
        meta: {
            resource: 'secretary.tab_details',
            action: 'view',
            title: 'label_secretaries',
        },
    },
    {
        path: '/secretaries/edit/:id',
        name: 'secretary_edit',
        component: () => import('@/views/pages/secretaries/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'secretary.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_secretaries',
        },
    },
    {
        path: '/secretaries/add',
        name: 'secretaries_new',
        component: () => import('@/views/pages/secretaries/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'secretary.tab_details',
            action: 'add',
            rerendering: true,
            title: 'label_secretaries',
        },
    },

    // PATTERNS
    {
        path: '/patterns',
        name: 'patterns',
        component: () => import('@/views/pages/pattern/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'pattern.tab_details',
            action: 'view',
            title: 'label_menu_pattern',
        },
    },
    {
        path: '/pattern/add',
        name: 'pattern_add',
        component: () => import('@/views/pages/pattern/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'pattern.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_menu_pattern',
        },
    },
    {
        path: '/pattern/edit/:id',
        name: 'pattern_edit',
        component: () => import('@/views/pages/pattern/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'pattern.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_menu_pattern',
        },
    },
    {
        path: '/pattern-details/:id',
        name: 'pattern_details',
        component: () => import('@/views/pages/contact-registry/itemDetails.vue'),
        meta: {
            // layout: 'full',
            resource: 'pattern.tab_details',
            action: 'view',
            title: 'label_menu_pattern',
        },
    },
    {
        path: '/pattern-categories',
        name: 'pattern_categories',
        component: () => import('@/views/pages/pattern/categories.vue'),
        meta: {
            // layout: 'full',
            resource: 'pattern.tab_details',
            action: 'view',
            title: 'label_menu_pattern',
        },
    },
    {
        path: '/pattern-logo',
        name: 'pattern_logo',
        component: () => import('@/views/pages/pattern/logo.vue'),
        meta: {
            // layout: 'full',
            resource: 'pattern.tab_details',
            action: 'edit',
            title: 'label_logo',
        },
    },
    {
        path: '/pattern-footer',
        name: 'pattern_footer',
        component: () => import('@/views/pages/pattern/footer.vue'),
        meta: {
            // layout: 'full',
            resource: 'pattern.tab_details',
            action: 'edit',
            title: 'label_footer',
        },
    },
    //SMS
    {
        path: '/sms-patterns',
        name: 'sms_patterns',
        component: () => import('@/views/pages/sms_pattern/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'sms_patterns.tab_details',
            action: 'view',
            title: 'label_sms',
        },
    },
    {
        path: '/sms-patterns/add',
        name: 'sms_pattern_add',
        component: () => import('@/views/pages/sms_pattern/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'sms_patterns.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_sms',
        },
    },
    {
        path: '/sms-patterns/edit/:id',
        name: 'sms_pattern_edit',
        component: () => import('@/views/pages/sms_pattern/editItem.vue'),
        meta: {
            // layout: 'full',
            resource: 'sms_patterns.tab_details',
            action: 'edit',
            rerendering: true,
            title: 'label_sms',
        },
    },
    {
        path: '/sms-categories',
        name: 'sms_pattern_categories',
        component: () => import('@/views/pages/sms_pattern/categories.vue'),
        meta: {
            // layout: 'full',
            resource: 'sms_patterns.tab_details',
            action: 'view',
            title: 'label_sms',
        },
    },
    // PASSWORD MANAGER
    {
        path: '/password-manager',
        name: 'password_manager',
        component: () => import('@/views/pages/password-manager/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'password_manager.tab_details',
            action: 'view',
            title: 'label_password_manager',
        },
    },
    {
        path: '/password-manager/categories',
        name: 'password_manager_categories',
        component: () => import('@/views/pages/password-manager/categories.vue'),
        meta: {
            // layout: 'full',
            resource: 'password_manager.categories',
            action: 'view',
            title: 'label_password_manager',
        },
    },

    {
        path: '/role-access',
        name: 'role_access',
        component: () => import('@/views/pages/role-access/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'role_access.tab_details',
            action: 'view',
            title: 'label_menu_role_access',
        },
    },
    {
        path: '/mlm-structure',
        name: 'mlm_structure',
        component: () => import('@/views/pages/mlm-structure/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'mlm.tab_details',
            action: 'view',
            title: 'label_mlm_structure',
        },
    },
    {
        path: '/departments',
        name: 'departments',
        component: () => import('@/views/pages/department/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'departments.tab_details',
            action: 'view',
            title: 'label_departments',
        },
    },
    // contacts
    {
        path: '/referral-contacts',
        name: 'referral_contacts',
        component: () => import('@/views/pages/referral/entry.vue'),
        meta: {
            // layout: 'full',
            resource: 'partner.referral',
            action: 'view',
            rerendering: true,
            title: 'label_marketing',
        },
    },
    {
        path: '/contacts-new',
        // replace:true,
        name: 'referral_stats',
        component: () => import('@/views/pages/referral/stats.vue'),
        meta: {
            // component_rerendering:true,
            // layout: 'full',
            resource: 'partner.referral_stats',
            action: 'view',
            rerendering: true,
            title: 'label_marketing',
        },
    },
    {
        path: '/languages',
        // replace:true,
        name: 'languages',
        component: () => import('@/views/pages/language/entry.vue'),
        meta: {
            resource: 'language.tab_details',
            action: 'view',
            title: 'label_language',
        },
    },
    {
        path: '/setting/theme-styles',
        // replace:true,
        name: 'setting_theme_styles',
        component: () => import('@/views/pages/setting/themeStylesSettings.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },
    {
        path: '/setting/selects',
        // replace:true,
        name: 'setting_selects',
        component: () => import('@/views/pages/setting/selects.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },
    {
        path: '/gcalendar/:code?',
        // replace:true,
        name: 'setting_google_calendar',
        component: () => import('@/views/pages/setting/addingClientGoogleCalendar.vue'),
        meta: {
            resource: 'Auth',
            // resource: 'settings.google_calendar',
            // action: 'edit',

            title: 'label_settings',
        },
    },
    {
        path: '/setting/select-options/:id',
        // replace:true,
        name: 'setting_select_options',
        component: () => import('@/views/pages/setting/selectOptions.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },
    {
        path: '/setting/invoice-details',
        // replace:true,
        name: 'setting_invoice_details',
        component: () => import('@/views/pages/setting/invoiceDetails.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },
    {
        path: '/setting/email-footer',
        // replace:true,
        name: 'setting_email_footer',
        component: () => import('@/views/pages/setting/emailFooterSetting.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },

    {
        path: '/setting/statements',
        // replace:true,
        name: 'setting_statements',
        component: () => import('@/views/pages/setting/statements.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },
    {
        path: '/setting/password-reset-email',
        // replace:true,
        name: 'setting_password_reset_email',
        component: () => import('@/views/pages/setting/emailPasswordReseting.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },
    {
        path: '/setting/email-to-responsible-user',
        // replace:true,
        name: 'setting_client_email_to_responsible_user',
        component: () => import('@/views/pages/setting/emailToResponsibleUser.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },
    {
        path: '/setting/email-agreement-deadline',
        // replace:true,
        name: 'setting_email_agreement_deadline',
        component: () => import('@/views/pages/setting/emailAgreementDeadline.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },
    {
        path: '/setting/email-notification-about-mail-adding',
        // replace:true,
        name: 'setting_notification_to_client_about_mail',
        component: () => import('@/views/pages/setting/emailAboutAddingEmail.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },
    {
        path: '/setting/system-images',
        // replace:true,
        name: 'setting_system_images',
        component: () => import('@/views/pages/setting/systemImages.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },
    {
        path: '/setting/password-change-email',
        // replace:true,
        name: 'setting_password_change_email',
        component: () => import('@/views/pages/setting/emailPasswordChanging.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },
    {
        path: '/setting/email-contact-meeting-text',
        // replace:true,
        name: 'setting_email_for_meeting',
        component: () => import('@/views/pages/setting/emailForMeeting.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },
    {
        path: '/setting/worktime-rounding',
        // replace:true,
        name: 'setting_worktime_rounding',
        component: () => import('@/views/pages/setting/workingTimeRoundingSetting.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },
    {
        path: '/setting/system-gmail',
        // replace:true,
        name: 'setting_system_gmail',
        component: () => import('@/views/pages/setting/systemGmailSetting.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_system_gmail_address',
        },
    },
    {
        path: '/failed-login-attempts',
        // replace:true,
        name: 'failed_login_attempts',
        component: () => import('@/views/pages/setting/failedLoginAttempts.vue'),
        meta: {
            resource: 'settings.tab_details',
            action: 'edit',
            title: 'label_settings',
        },
    },
    {
        path: '/user/email_settings',
        // replace:true,
        name: 'user_email_settings',
        component: () => import('@/views/user-dropdown/emailSetting.vue'),
        meta: {
            resource: 'Auth',
            // action: 'view',
            title: 'label_settings',
        },
    },
    {
        path: '/sms_settings',
        // replace:true,
        name: 'sms_settings',
        component: () => import('@/views/pages/setting/smsSettings.vue'),
        meta: {
            resource: 'Auth',
            // action: 'view',
            title: 'label_settings',
        },
    },
    {
        path: '/user/change-password',
        // replace:true,
        name: 'user_change_password',
        component: () => import('@/views/user-dropdown/changePassword.vue'),
        meta: {
            resource: 'Auth',
            // action: 'view',
            title: 'label_settings',
        },
    },

    {
        path: '/user-notifications',
        // replace:true,
        name: 'user_notifications',
        component: () => import('@/views/user-dropdown/userNotificationTable.vue'),
        meta: {
            resource: 'Auth',
            // action: 'view',
            title: 'label_settings',
        },
    },
    {
        path: '/user/settings',
        name: 'user_settings',
        component: () => import('@/views/user-dropdown/settings.vue'),
        meta: {
            resource: 'Auth',
            // action: 'view',
            title: 'label_settings',
        },
    },


]
