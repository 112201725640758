String.prototype.ucFirst = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
String.prototype.hashCode = function () {
    var hash = 0, i, chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};
String.prototype.includes = function (substr) {
    return this.indexOf(substr) == -1 ? false : true;
}
String.prototype.br2nl = function(replaceMode) {
    if(!this) return;
    let replaceStr = (replaceMode) ? '' : "\n";
    // Includes <br>, <BR>, <br />, </br>
    return this.replace(/<\s*\/?br\s*[\/]?>/gi, replaceStr);
}
String.prototype.nl2br = function() {
    if(!this) return;
    return this.replace(new RegExp('\r?\n','g'), "<br/>");
}
Number.prototype.formatPrice = function (withoutZero) {
    return String(this).formatPrice(withoutZero);
}
String.prototype.formatPrice = function (withoutZero) {
    let maxFractionDigits = 2;
    let number = this;
    let sign = number.charAt(0);

    if(sign != '-' && sign != '+'){
        sign = '';
    }
    if (number !== undefined && number !== '') {
        number = number.replace(/[^0-9.,]/gi, '').replace(/\,/gi, '.');
        let parts = number.split('.');

        if (parts[1] !== undefined) { //several dots bug
            if(parts[1].length > maxFractionDigits ){
                parts[1] = parts[1].substr(0,maxFractionDigits);
            }
            number = parts[0] + '.' + parts[1];
        }

        let options = {};
        if (withoutZero) {
            options.maximumFractionDigits = maxFractionDigits;
            options.minimumFractionDigits = (parts[1])? parts[1].length : 0;
        } else {
            options.minimumFractionDigits = 2;
            options.maximumFractionDigits = maxFractionDigits;
        }

        if (number.slice(-1) != '.') {
            number = new Intl.NumberFormat('ru-RU', options).format(number);
        } else {
            number = new Intl.NumberFormat('ru-RU', options).format(number.slice(0, -1)) + ',';
        }
    }

    return sign + number;
}
String.prototype.isNumeric = function(){ return !isNaN(parseFloat(this)) && !isNaN(this - 0) };

String.prototype.formatPriceToNumber = function () {

    let number = this;
    if (number !== undefined && number !== '') {
        number = number.replace(/\,/gi, '.').replace(/\s/gi, '');
    }
    return number;
}
Array.prototype.pluck = function (key) {
    return this.map(function (object) {
        return object[key];
    });
}
Array.prototype.deleteVal = function (val) {
    let ind = this.indexOf(val);
    if (ind !== -1) {
        this.splice(ind, 1);
        return this;
    }
    return this;
}
Array.prototype.deleteByVal = function (key, val) {
    return this.filter(function (element) {
        return element[key] != val;
    });
}
Array.prototype.findWhere = function (key, val) {
    let items = this.filter(function (element) {
        return element[key] == val;
    });
    // console.log('items find',items[0]);
    return items[0];
}
Array.prototype.findAll = function (key, val) {
    let items = this.filter(function (element) {
        return element[key] == val;
    });
    // console.log('items find',items[0]);
    return items;
}
Array.prototype.unique = function (key) {
    let obj = {};
    let res = [];
    if (key) {
        for (let i = 0; i < this.length; i++) {
            if (!this[i][key]) continue;
            obj[this[i][key]] = this[i];
        }
    }
    for (let prop in obj) {
        res.push(obj[prop]);
    }
    return res;
}
Object.defineProperty(Object.prototype, 'objectToQueryString', {

    value: function (withoutEpmty) {

        let qString = '';

        let keys = Object.keys(this);
        let length = keys.length;
        let is_first = true;

        for (let i = 0; i < length; i++) {
            let val = this[keys[i]];
            if (!val) val = '';
            if (typeof val != 'array' && typeof  val != 'object') {
                if(!withoutEpmty || (withoutEpmty  && this[keys[i]])){
                     qString += (!is_first? '&' : '') + keys[i] + '=' + this[keys[i]];
                    is_first = false;
                }

            } else {
                for (let j = 0; j < val.length; j++) {
                    if(!withoutEpmty || (withoutEpmty  && val[j]))
                    qString += keys[i] + '[]=' + val[j] + ((i === (length - 1) && j == (val.length - 1)) ? '' : '&');
                }
            }
        }

        return qString? '?'+ qString : '';

    },
    enumerable: false,

});

// !!! also need add them to global mixins else will be undefined;
import moment from "moment";

window['formatDate'] = function (val, toFormat, fromFormat,) {

    if (!val) return '';

    if (fromFormat === 'unix') {
        // console.log('here 2')
        return moment.unix(val).format(toFormat ? toFormat : 'DD-MM-YYYY hh:mm:ss');
    }
    if (typeof val === 'object' && !fromFormat) {
        return moment(val).format(toFormat ? toFormat : 'DD-MM-YYYY hh:mm:ss');
    }

    return moment(val, fromFormat).format(toFormat ? toFormat : 'DD-MM-YYYY hh:mm:ss');
};
window['formatSeconds'] = function (val, toFormat) {

    if (!val) return '';

    return moment().startOf('day')
        .seconds(val)
        .format(toFormat ? toFormat : 'H:mm:ss');
    // return moment(val, fromFormat).format(toFormat? toFormat:'DD-MM-YYYY hh:mm:ss');
};
window['formatNlToBr'] = function (str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
};

String.prototype.sprintf = function () {
    let str = this;
    let args = Array.prototype.slice.call(arguments, 0);
    let i = 0;
    return str.replace(/%(s|d|0\d+d|%)/g, function (x, type) {
        var value = args[i++];

        switch (type) {
            case 's':
                return value;
            case 'd':
                return parseInt(value, 10);
            case '%':
                return '%';
            default:
                value = String(parseInt(value, 10));
                var n = Number(type.slice(1, -1));
                return '0'.repeat(n).slice(value.length) + value;
        }
    });
};

window['htmlEncode'] = function (s)
{
    let el = document.createElement("div");
    el.innerText = el.textContent = s;
    s = el.innerHTML;
    el.remove();
    return s;
};


