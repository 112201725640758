import { Ability } from '@casl/ability'
import { initUserAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
let userData = localStorage.getItem('userData');

if(userData && userData !== 'undefined'){
    userData = JSON.parse(userData);
} else {
    userData = false;
}

let existingAbility = initUserAbility(userData);

export default new Ability(existingAbility)
