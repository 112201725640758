export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
]

export function initUserAbility(userData) {
    let abilities = false;

    if(userData && userData.role == 'admin') {
        abilities = [{
            "action": "manage",
            "subject": "all"
        }]
    } else if(userData) {

        abilities = userData.ability;

        // abilities.push({action: "manage", subject: "ACL"});

    } else {
        abilities = initialAbility
    }

    return abilities;
}
// export const _ = undefined
