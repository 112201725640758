import jwtDefaultConfig from './jwtDefaultConfig'
import router from '@/router'
// import moment from "moment";

import FingerprintJS from '@fingerprintjs/fingerprintjs'

const fpPromise = FingerprintJS.load();

export async function getFingerprint() {
    const fp = await fpPromise;
    const result = await fp.get();
    return result.visitorId;
}

import Swal from 'sweetalert2'

import I18n from '@src/libs/i18n'
import { initUserAbility } from '@/libs/acl/config'

export default class JwtService {

    axiosInstanse = null;

    fingerprint = '';

    jwtConfig = {...jwtDefaultConfig};

    logout_timeout = false;
    refresh_timeout = false;

    logoutCheckingInterval_id = false;

    updatingUserRequest = false;

    constructor(axiosInstanse, jwtOverrideConfig) {

        this.axiosInstanse = axiosInstanse;

        this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig};

        localStorage.removeItem('token-refresh');

        this.axiosInstanse.interceptors.request.use(
            request => {
                // Get token from localStorage
                const accessToken = this.getToken();

                if (accessToken) {
                    request.headers['X-API-KEY'] = `${accessToken}`
                }
                return request
            },
            error => Promise.reject(error),
        );

        let selfClass = this;

        this.axiosInstanse.interceptors.response.use(
            response => {

                let headers = response.headers;

                if (headers['x-user-updated']) {

                    let auth_user = JSON.parse(localStorage.getItem('userData'));

                    if (auth_user) {

                        if ((auth_user.updated_at == null && headers['x-user-updated']) || (auth_user.updated_at < headers['x-user-updated']) && !selfClass.updatingUserRequest) {

                            selfClass.updatingUserRequest = true;
                            selfClass.axiosInstanse
                                .get('/user/' + auth_user.id, {})
                                .then(function (resp) {
                                    selfClass.updateUserSessionData(resp.data);
                                    selfClass.updatingUserRequest = false;
                                }).catch(error => {
                                selfClass.updatingUserRequest = false;
                                // console.log('error',error);
                            });
                        }
                    }
                }

                return response;
            },
            error => {

                const {config, response} = error;

                if(response) {
                    if (response.status === 403) { //no permissions

                    }  else if (response.status === 401){ // not authorized

                        if (localStorage.hasOwnProperty('userData')) {
                            if(response.data) {
                                this.clearStorageAndRedirect(true);
                            }
                        }

                    }
                }

                return Promise.reject(error)
            },
        )
    }

    updateUserSessionData(data) {
        let updatedUserData = JSON.stringify(data);
        localStorage.setItem('userData', updatedUserData);
        sessionStorage.removeItem('userData', updatedUserData);
        window.vm.$ability.update(initUserAbility(data));
    }

    showExpirationAlert() {
        Swal.fire({
            title: I18n.t('label_warning'),
            text: I18n.t('label_logout_warning'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: I18n.t('label_refresh'),
            cancelButtonText: I18n.t('label_logout'),
            showClass: {
                popup: 'animate__animated animate__flipInX',
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
        }).then(result => {
            if (result.value) {
                localStorage.setItem('lastActivity', Math.floor((new Date()).getTime() / 1000));
                clearInterval(this.logout_timeout);
                this.refreshTokenAndProcess();
            } else if (result.dismiss === 'cancel') {
                this.clearStorageAndRedirect(true);
            }
        })
    }

    hideExpirationAlert(){
        Swal.close();
    }

    isMainTab(){
        return localStorage.getItem('t-main') == window.mainTabIndex
    }

    setRefreshUpdatingInterval() {

        let timeToShowAlertBeforeLogout = 3 * 60; //for test 1
        let allowedTimeWithoutActivity = 6 * 60;//for test 2

        clearInterval(this.logoutCheckingInterval_id);

        this.logoutCheckingInterval_id = setInterval(() => { //each 5 sec

            let tokenData = this.parseJwt(this.getToken());
            // console.log('------');
            // console.log('is_main_tab',window.mainTabIndex, this.isMainTab());
            // console.log('parsed token',tokenData);
            if(!tokenData) {
                this.clearStorageAndRedirect(true);
            }

            let expirationDate = tokenData.exp; //for test set on server 5 min
            let currentDate = Math.floor((new Date()).getTime() / 1000);
            let lastActivityDate = parseInt(localStorage.getItem('lastActivity'));


            let timeWithoutActivity = currentDate - lastActivityDate;
            let tokenRemainedTime = expirationDate - currentDate ;
            if (tokenRemainedTime < 0) {
                this.clearStorageAndRedirect(true);
            }
            let isUserActive = timeWithoutActivity < allowedTimeWithoutActivity;


            // console.log('expiration date: ', moment.unix(expirationDate).format('HH:mm:ss'));
            // console.log('current Date: ', moment.unix(currentDate).format('HH:mm:ss'));
            // console.log('timeWithoutActivity: ', (timeWithoutActivity/60).toFixed(2)+ ' min');
            // console.log('tokenRemainedTime: ', tokenRemainedTime + 'sec');
            // console.log('user is active: ', isUserActive);


            if (tokenRemainedTime > allowedTimeWithoutActivity ){
                // to sync with another opened tabs if they was updated
                if(this.refresh_timeout)
                    this.refresh_timeout = clearTimeout(this.refresh_timeout);

                if (tokenRemainedTime > timeToShowAlertBeforeLogout && isUserActive){
                    if(this.logout_timeout)
                        this.logout_timeout = clearTimeout(this.logout_timeout);
                    this.hideExpirationAlert();
                }
            }

            if(tokenRemainedTime >= allowedTimeWithoutActivity && !isUserActive) {
                if(!this.refresh_timeout && !this.logout_timeout) {

                    this.showExpirationAlert();

                    this.logout_timeout = setTimeout(() => {
                        this.hideExpirationAlert();
                        this.clearStorageAndRedirect(true);
                        this.logout_timeout = clearTimeout(this.logout_timeout);
                    },  timeToShowAlertBeforeLogout * 1000);

                }
            } else if(tokenRemainedTime < allowedTimeWithoutActivity && tokenRemainedTime >= timeToShowAlertBeforeLogout) {

                if(!isUserActive && !this.refresh_timeout) {
                    this.refresh_timeout = setTimeout(() => {
                        // console.log('silent refresh because of user activity');
                        if(this.isMainTab())
                        this.refreshTokenAndProcess();
                        this.refresh_timeout = clearTimeout(this.refresh_timeout);
                    }, (tokenRemainedTime * 1000 )); //before 20sec to end make refresh request
                }

            } else if(tokenRemainedTime <= timeToShowAlertBeforeLogout){

                if(!isUserActive) {
                    if(!this.refresh_timeout && !this.logout_timeout) {

                        this.showExpirationAlert();

                        this.logout_timeout = setTimeout(() => {
                            this.hideExpirationAlert();
                            this.clearStorageAndRedirect(true);
                            this.logout_timeout = clearTimeout(this.logout_timeout);
                        },  ((tokenRemainedTime <= timeToShowAlertBeforeLogout)? tokenRemainedTime : timeToShowAlertBeforeLogout) * 1000);

                    }
                } else {
                    // (isUserActive && (allowedTimeWithoutActivity-timeWithoutActivity + tokenRemainedTime) < timeToShowAlertBeforeLogout)
                    if(!this.refresh_timeout) {
                        this.refresh_timeout = setTimeout(() => {
                            if(this.isMainTab())
                            this.refreshTokenAndProcess();
                            this.refresh_timeout = clearTimeout(this.refresh_timeout);
                        }, (tokenRemainedTime * 1000 )); //before 20sec to end make refresh request
                    }
                }

            }

        }, 10000);

    }

    refreshTokenAndProcess(successCallback) {

        if (localStorage.getItem('getting-refresh_token') != 1) { // to avoid several updating between several tabs
            localStorage.setItem('getting-refresh_token', 1);

            this.refreshToken().then(r => {

                // console.log('refresh success');
                this.setToken(r.data.accessToken);
                this.setRefreshToken(r.data.refreshToken);

                this.setRefreshUpdatingInterval();

                if (typeof successCallback == 'function') {
                    successCallback.apply(this, [r]);
                }

                localStorage.removeItem('getting-refresh_token');

            }).catch((error) => {
                localStorage.removeItem('getting-refresh_token');
                this.clearStorageAndRedirect(false);
            });

        } else {
            //do nothing;
        }

    }

    clearStorageAndRedirect(redirectToLogin) {

        if(localStorage.getItem(this.jwtConfig.storageTokenKeyName)) {
            localStorage.removeItem('getting-refresh_token');
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);
            localStorage.removeItem('userData');
            sessionStorage.removeItem('userData');
        }

        this.hideExpirationAlert();

        this.logoutCheckingInterval_id = clearInterval(this.logoutCheckingInterval_id);
        if(this.logout_timeout)
            this.logout_timeout = clearTimeout(this.logout_timeout);
        if(this.refresh_timeout)
            this.refresh_timeout = clearTimeout(this.refresh_timeout);
        if (redirectToLogin) {
            router.push({name: 'auth-login'});
        }
    }

    parseJwt(token) {
        if(!token) return null;
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    // login(...args) {
    //
    //   return this.axiosInstanse.post(this.jwtConfig.loginEndpoint, ...args)
    // }
    //
    // register(...args) {
    //   return this.axiosInstanse.post(this.jwtConfig.registerEndpoint, ...args)
    // }

    async refreshToken() {
        if (!this.fingerprint) {
            let self = this;
            const fingerprint = await getFingerprint();
            return self.axiosInstanse.post(self.jwtConfig.refreshEndpoint, {fingerprint: fingerprint}, {
                headers: {
                    'x-refresh-token': self.getRefreshToken()
                }
            });
        } else {
            return this.axiosInstanse.post(this.jwtConfig.refreshEndpoint, {fingerprint: this.fingerprint}, {
                headers: {
                    'x-refresh-token': this.getRefreshToken()
                }
            });
        }
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    getRefreshToken() {
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }

    setRefreshToken(value) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    }
}
