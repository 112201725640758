import Vue from 'vue'
import Toast, { TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css"
/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */

Vue.use(Toast, {
    transition: "Vue-Toastification__bounce",
    // transition: "Vue-Toastification__fade",
    maxToasts: 3,
    newestOnTop: true,
    position: "top-right",
    timeout: 4000,
    // escapeHtml: false,
    // progressBar: true,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: false,
    draggable: true,
    draggablePercent: 0.7,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false,
    //         [TYPE.ERROR]: {
//             timeout: 10000,
//             closeButton: true,
//         },
})

// import { createToastInterface } from "vue-toastification";
//
// const pluginOptions = {
//     transition: "Vue-Toastification__fade",
//     maxToasts: 3,
//     newestOnTop: true,
//     position: "top-right",
//     timeout: 3000,
//     progressBar: true,
//     closeOnClick: true,
//     pauseOnFocusLoss: true,
//     pauseOnHover: false,
//     draggable: true,
//     draggablePercent: 0.7,
//     showCloseButtonOnHover: false,
//     hideProgressBar: true,
//     closeButton: "button",
//     icon: true,
//     rtl: false,
// };
//
// export const toast = createToastInterface(pluginOptions);
